<template>
  <div>
    <el-dialog :visible.sync='drawer' v-bind='$attrs' v-on='$listeners'
               :title='title' width='800px' append-to-body>
      <div style='padding: 10px'>
        <div>
          <el-checkbox-group v-model='checkList'>
            <el-checkbox :label='month' v-for='(month) in quarterlyMonths' :key='month'>{{ month }}月</el-checkbox>
          </el-checkbox-group>
        </div>
        <div style='margin: auto'>
          <el-table :data='setData' border max-height='800px' style='width: 100%'>
            <el-table-column label='平台' prop='platform_name' min-width='100' align='center'></el-table-column>
            <el-table-column label='涨粉目标' prop='val' min-width='160' align='center'>
              <editable-cell
                :show-input='row.editMode'
                slot-scope='{$index,row,column}'
                v-model='row[column.property]'
                :is-input-number='false'>
                          <span slot='content'>
                            <span>{{ row[column.property] || '/' }}</span>
                            <i class='el-icon-edit-outline default-edit-icon'></i>
                          </span>
              </editable-cell>
            </el-table-column>
            <el-table-column label='影响数据量' prop='total' align='right' min-width='100'>
            </el-table-column>
          </el-table>
          <div style='text-align: right;margin-right: 10px;margin-top: 10px'>
            <el-button size='medium' @click='drawer=false'>取消</el-button>
            <!--            <el-button size='medium' type='primary' @click='$refs.drawer.closeDrawer()' :loading='loading'>-->
            <!--              {{ loading ? '提交中 ...' : '确 定' }}-->
            <!--            </el-button>-->
            <el-button size='medium' type='primary' :disabled='loading' :loading='loading' @click='handleSave'>
              {{ loading ? '提交中 ...' : '确 定' }}
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'

export default {
  name: 'BatchSetPaTrend',
  components: { EditableCell },
  props: {
    typeRange: {
      type: String,
      default() {
        // kol  dept chief
        return 'kol'
      }
    },
    map: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      searchCondition: { year: null, quarter: null },
      checkList: [],
      title: '批量设置涨粉数据',
      setData: [],
      kolRange: [],
      loading: false
    }
  },
  watch: {
    map: {
      handler(_) {
        this.setData = [...[], ..._]
      },
      deep: true
    }
  },
  computed: {
    quarterlyMonths() {
      const quarter = this.searchCondition.quarter
      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3]
    }
  },
  methods: {
    show(searchCondition, kolRange) {
      this.loading = false
      this.searchCondition = searchCondition
      this.kolRange = kolRange
      this.title = `批量设置当前【${kolRange.length}个】红人的涨粉数据(${searchCondition.year || ''}年第${searchCondition.quarter}季度)`
      // this.$notify.success('涨粉设置')
      this.drawer = true
      // this.getBatchSetting()
    },
    handleClose(done) {
      if (this.loading) {
        return
      }
      this.$confirm('确定要提交吗？')
        .then(() => {
          this.handleSave(done)
        })
        .catch(() => {
          this.drawer = false
        })
    },
    async getBatchSetting() {
      this.setData = []
      // 获取最新平台信息
      let { list } = await this.$api.getPaKolTrendBatchSetting()
      if (list) {
        list.forEach(item => {
          this.setData.push({ platform_name: item.name, platform_code: item.code, val: null })
        })
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleSave() {
      if (['kol', 'dept'].indexOf(this.typeRange) === -1) {
        this.$notify.warning('批量操作类型不对！！！')
        return false
      }
      if (this.checkList.length === 0) {
        this.$notify.warning('请先勾选月份！！！')
        return false
      }
      let selectMonths = this.checkList.sort().join('、')
      let msg = `此操作将直接修改全部红人${this.searchCondition.year}年${selectMonths}月《考核的绩效目标》, 是否继续?`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        let postData = {
          condition: {
            year: this.searchCondition.year,
            quarter: this.searchCondition.quarter,
            months: this.checkList,
            artist_ids: this.kolRange
          },
          data: this.setData
        }

        if (this.typeRange === 'kol') {
          let { list } = await this.$api.batchSetPaKolTrendData(postData)
          this.setData = list
        } else if (this.typeRange === 'dept') {
          let { list } = await this.$api.batchSetPaDeptTrendData(postData)
          this.setData = list
        } else {
          // // let list  = []
          // let {list} = await this.$api.batchSetPaChiefTrendData(postData)
          // this.setData = list
        }
        this.loading = false
        //保存后回调-用于刷新列表
        this.$emit('saved')
        // this.drawer = false
        setTimeout(() => {
          this.drawer = false
        }, 1000)
        // this.timer = setTimeout(() => {
        //   done()
        //   // 动画关闭需要一定的时间
        //   setTimeout(() => {
        //     this.drawer = false
        //   }, 400)
        // }, 2000)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style scoped>
.default-edit-icon {
  float: right;
}
</style>
