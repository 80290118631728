<template>
  <div>
    <el-dialog :visible.sync='dialogVisible' v-bind='$attrs' v-on='$listeners' @opened='onOpened' @close='onClose'
               :title='title' width='75%'>
      <el-form :model='searchCondition' inline size='mini'>
        <!--        <el-form-item v-if='showMore'>-->
        <!--          <McnDeptSelect ref='deptSelect' :multiple='true' @handleSelect='selectDept' @loaded='loadDeptSetting'-->
        <!--                         :search-condition='searchCondition'></McnDeptSelect>-->
        <!--&lt;!&ndash;          <el-link icon='el-icon-refresh' @click='loadMcnDeptList'></el-link>&ndash;&gt;-->
        <!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->
        <!--        <el-form-item v-if='showMore'>-->
        <!--          <CompanySelect ref='refCompanySelect' :multiple='true' @handleSelect='selectDept' />-->
        <!--        </el-form-item>-->
        <el-form-item>
          <artist-search ref='refArtistSearch' @handleSelect='handleSelectArtist' style='width: 400px'></artist-search>
        </el-form-item>
        <el-form-item>
          <el-button size='mini' type='primary' @click='handleQuery'>查询</el-button>
          <el-button size='mini' type='text' @click='showMore=!showMore'
                     :icon="!showMore?'el-icon-caret-bottom':'el-icon-caret-top'">更多
          </el-button>
        </el-form-item>
      </el-form>
      <div style='margin-bottom: 8px' v-if='showMore'>
        <el-button :disabled='!multiple||loadingInit' :loading='loadingInit' type='warning'
                   @click='handleNeedCheckSelection("Y")'>批量考核({{
            selection.length
          }})项
        </el-button>
        <el-button :disabled='!multiple||loadingInit' :loading='loadingInit' type='danger'
                   @click='handleNeedCheckSelection("N")'>批量不考核({{
            selection.length
          }})项
        </el-button>
        <!--        <el-button type='warning' @click='initQuarterSetting' :disabled='loadingInit'>初始化</el-button>-->
        <!--        <el-button icon='el-icon-setting' @click='showDeptSetting'>考核部门</el-button>-->
      </div>
      <div class='default-table'  v-loading='loadingStatus'
           :element-loading-text='loadingText'
           element-loading-spinner='el-icon-loading'>
        <el-table
                  :data='dataList'
                  :highlight-current-row='true'
                  @selection-change='handleSelectionChange'
                  height='600px'>
          <el-table-column type='selection' width='55' v-if='showMore'></el-table-column>
          <el-table-column type='index' label='序号' width='55'></el-table-column>
          <el-table-column label='红人昵称' align='left' min-width='300' prop='nickname' show-overflow-tooltip>
            <template slot-scope='{row}'>
              <span>{{ row.nickname || '' }}</span>
              <span>【{{ row.group_name || '' }}】</span>
              <span :class='[row.sign_status==="Y"?"sign-status":"unsign-status"]'>
              {{ row.sign_status === 'Y' ? '在约' : '解约' }}</span>
              <span
                style='margin-left: 10px;color: var(--primary)'>{{
                  $utils.parseTime(row.sign_start, '签约于{y}年{m}月{d}日') || ''
                }}</span>
              &nbsp;
              <span v-if='row.sign_status!=="Y"'>{{ $utils.parseTime(row.fired_at, '于{y}年{m}月{d}日') }}</span>
            </template>
          </el-table-column>
          <el-table-column label='平台名称' align='center' width='100' prop='platform_name' />
          <!--          <el-table-column label='收入分类' align='center' min-width='100' prop='income_type'-->
          <!--                           v-if="performanceType==='revenue'" />-->
          <el-table-column label='是否考核' align='center' width='100'>
            <template slot-scope='{row}'>
              <el-checkbox true-label='Y' false-label='N' v-model='row.need_check'
                           @change='handleChangeSingleCheck(row)'></el-checkbox>
              <!--            <span>{{ row.need_check }}</span>-->
            </template>
          </el-table-column>
          <el-table-column label='操作' align='center'
                           v-if="performanceType==='revenue'" width='140'>
            <template slot-scope='{row}'>
              <el-button
                size='mini'
                type='text'
                icon='el-icon-edit'
                @click='updatePerformanceSetting(row,$event)'
              >收入分类（{{ calcRate(row) }}）
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style='display: flex;flex-direction: row;gap: 5px;justify-content: space-between;align-items: center'>
          <el-link type='primary' icon='el-icon-refresh' @click='getList'>刷新</el-link>
          <m-pagination @pagination='getList' :total.sync='total' :limit.sync='pageData.page_size'
                        :page.sync='pageData.current_page'></m-pagination>
        </div>

        <!--      <el-form label-width='100px'>-->
        <!--        <el-form-item style='text-align: center;margin-left:-100px;margin-top:10px;'>-->
        <!--          <el-button size='medium' type='primary' @click='submitFormList()'>提&nbsp;&nbsp;交</el-button>-->
        <!--        </el-form-item>-->
        <!--      </el-form>-->
      </div>

      <!--          平台收入分类设置-->
      <PaIncomeSetting :performance-type='`revenue`' ref='refPerformanceSetting' />
      <!--  考核部门设置-->
      <PaDeptSetting ref='refDeptSetting'></PaDeptSetting>
    </el-dialog>
  </div>

</template>

<script>


import CompanyCascader from '@/components/dept/CompanyCascader'
import McnDeptSelect from '@/pages/pa/kol/McnDeptSelect'
import PaDeptSetting from '@/pages/pa/components/PaDeptSetting'
import ArtistSearch from '@/components/artist/ArtistSearch'
import PaIncomeSetting from '@/pages/pa/components/PaIncomeSetting'


export default {
  name: 'CheckSetting',
  components: { PaIncomeSetting, ArtistSearch, PaDeptSetting, McnDeptSelect, CompanyCascader },
  inheritAttrs: false,
  data() {
    return {
      loadingStatus: false,
      loadingInit: false,
      loadingText: '加载中',
      mergeFields: ['nickname'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      dataList: [],
      total: 0,
      pageData: { current_page: 1, page_size: 10 },
      orderBy: { artist_id: 'asc' },
      searchCondition: { year: null, quarter: null, dept_ids: null, artist_id: null },
      showQuery: false,
      showMore: false,
      dialogVisible: false,
      performanceType: 'trend',//revenue|trend
      platformsMap: {},//考核平台
      incomeTypes: {},//收入分类
      selection: [],
      multiple: false
    }
  },
  computed: {
    title() {
      return `${this.performanceType === 'trend' ? '涨粉' : '营收'}考核设置（${this.searchCondition.year}年第${this.searchCondition.quarter}季度）`
    }
  },
  watch: {},
  created() {
  },
  mounted() {

  },
  methods: {
    show(searchCondition, performanceType) {
      //checkSetting
      this.searchCondition.year = searchCondition.year
      this.searchCondition.quarter = searchCondition.quarter
      this.searchCondition.dept_ids = [searchCondition.group_id]
      // this.searchCondition = { ...searchCondition }
      // this.showQuery = !(searchCondition.dept_ids && searchCondition.dept_ids.length > 0)
      // if (this.showQuery) {
      //   this.$notify.warning('请先选勾选部门')
      //   return
      // }
      this.performanceType = performanceType
      this.dialogVisible = true
      this.showMore = false
      this.loadingInit = false
      this.$nextTick(() => {
        // this.loadMcnDeptList()
        this.getList()
      })

    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {

          condition[field] = this.searchCondition[field]
        }
      })


      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    async getList() {
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      if (this.performanceType === 'trend') {
        let { list, pages, platformsMap } = await this.$api.getPaKolTrendQuarterSetting(searchCondition)
        // let { list, pages } = await this.$api.getPaKolTrendQuarterSettingList(searchCondition)
        this.platformsMap = platformsMap
        this.dataList = list || []
        this.pageData.current_page = pages.current_page || 1
        this.pageData.page_size = pages.page_size || 10
        this.total = pages.total || 0

      } else if (this.performanceType === 'revenue') {
        // this.$notify.info('读取营收考核设置')
        let { list, pages, platformsMap, incomeTypes } = await this.$api.getPaKolRevenueQuarterSetting(searchCondition)
        // let { list, pages } = await this.$api.getPaKolRevenueQuarterSettingList(searchCondition)
        this.platformsMap = platformsMap
        this.incomeTypes = incomeTypes
        this.dataList = list || []
        this.pageData.current_page = pages.current_page || 1
        this.pageData.page_size = pages.page_size || 10
        this.total = pages.total || 0
      }

    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      // console.log(fields)
      this.dataList.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.dataList[index][field] === this.dataList[index - 1][field]) {
              if (field === 'platform_name') {
                //平台列，特殊处理
                if (this.dataList[index]['nickname'] === this.dataList[index - 1]['nickname']) {
                  this.spanObj[field][this.positionObj[field]] += 1
                  this.spanObj[field].push(0)
                } else {
                  this.spanObj[field].push(1)
                  this.positionObj[field] = index
                }
              } else {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              }

            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      const fields = this.mergeFields
      if (column.property && fields.indexOf(column.property) > -1) {
        const _row = this.spanObj[column.property][rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }

      //末尾查询一行合计：
      // if (rowIndex === this.dataList.length - 1) {
      //   // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
      //   if ((columnIndex + 1) % 3 === 0) {
      //     return [1, 3]
      //   } else {
      //     return [0, 0]
      //   }
      // } else if (rowIndex === this.dataList.length - 2) {
      //   // 本组总涨粉：
      //   if (columnIndex === 0) {
      //     return [0, 0]
      //   } else if (columnIndex === 1) {
      //     return [1, 2]
      //   } else {
      //     return [1, 1]
      //   }
      // } else {
      //   const fields = this.mergeFields
      //   if (column.property && fields.indexOf(column.property) > -1) {
      //     const _row = this.spanObj[column.property][rowIndex]
      //     const _col = _row > 0 ? 1 : 0
      //     return {
      //       rowspan: _row,
      //       colspan: _col
      //     }
      //   }
      // }

    },
    // async submitFormList() {
    //   if (this.performanceType === 'trend') {
    //     let content = this.dataList.map((item) => {
    //       return {
    //         artist_id: item.artist_id,
    //         nickname: item.nickname,
    //         platform_code: item.platform_code,
    //         platform_name: item.platform_name,
    //         // income_code: item.income_code,
    //         need_check: item.need_check
    //       }
    //     })
    //     let searchCondition = { year: this.searchCondition.year, quarter: this.searchCondition.quarter }
    //     await this.$api.savePaKolTrendQuarterSetting(Object.assign(searchCondition, { content: content }))
    //     this.$emit('savedSetting')
    //   } else if (this.performanceType === 'revenue') {
    //     let content = this.dataList.map((item) => {
    //       return {
    //         artist_id: item.artist_id,
    //         nickname: item.nickname,
    //         platform_code: item.platform_code,
    //         platform_name: item.platform_name,
    //         income_code: item.income_code,
    //         income_type: item.income_type,
    //         need_check: item.need_check
    //       }
    //     })
    //     let searchCondition = { year: this.searchCondition.year, quarter: this.searchCondition.quarter }
    //     await this.$api.savePaKolRevenueQuarterSetting(Object.assign(searchCondition, { content: content }))
    //     this.$emit('savedSetting')
    //   }
    //
    //   this.dialogVisible = false
    //
    // },

    async initQuarterSetting() {
      this.loadingInit = true
      if (this.performanceType === 'trend')
        await this.$api.initPaKolTrendSetting(this.searchCondition)

      if (this.performanceType === 'revenue')
        await this.$api.initPaKolRevenueSetting(this.searchCondition)

      this.loadingInit = false
      await this.getList()
    },
    onOpened() {

    },
    onClose() {
      this.dataList = []
    },
    selectDept(val) {
      this.searchCondition.dept_ids = val || []
    },
    handleSelectArtist(obj) {
      if (obj && obj.id)
        this.searchCondition.artist_id = obj.id
      else
        this.searchCondition.artist_id = null
    },
    reset() {
      this.pageData.current_page = 1
    },
    handleQuery() {
      //查询配置
      this.pageData.current_page = 1
      this.getList()
    },
    calcRate(currentRow) {
      let selectCount = 0
      let children = { ...currentRow.children }
      let total = 0
      if (children) {
        Object.keys(children).forEach((income_code) => {
          total++
          let item = children[income_code]
          // let currIncomeType = item['income_code']
          if (item.need_check === 'Y') {
            selectCount++
          }
        })
      }
      return `${selectCount}/${total}`
    },
    // eslint-disable-next-line no-unused-vars
    updatePerformanceSetting(row, e) {
      this.$nextTick(() => {
        this.$refs['refPerformanceSetting'].init(this.platformsMap, this.incomeTypes, row)
      })
    },
    savedPlatformEvent() {
      //
    },
    async handleChangeCheck(row) {
      this.loadingInit = true
      if (this.performanceType === 'trend')
        await this.$api.changePaKolTrendQuarterNeedCheck(row)

      if (this.performanceType === 'revenue')
        await this.$api.changePaKolRevenueQuarterNeedCheck(row)

      this.loadingInit = false
      await this.getList()
      this.$emit('savedSetting')
      // this.$notify.info('操作成功')
    },
    // loadMcnDeptList() {
    //   if (this.$refs['deptSelect'])
    //     this.$refs['deptSelect'].getList()
    // },
    handleSelectionChange(val) {
      this.selection = val
      this.multiple = this.selection.length > 0
    },
    handleNeedCheckSelection(need_check) {
      this.loadingInit = true
      let rows = this.selection.map((item) => {
        item.need_check = need_check
        return item
      })

      this.handleChangeCheck({ multiple: rows })

    },
    handleChangeSingleCheck(row) {
      // if (row.platform_code) {
      //   this.$notify.success(row.platform_code)
      // }
      this.handleChangeCheck({ single: row })
    },
    showDeptSetting() {
      if (this.$refs['refDeptSetting'])
        this.$refs['refDeptSetting'].show(this.searchCondition)
    },
    loadDeptSetting(list) {

      if (!list || list.length === 0) {
        this.$confirm(`${this.searchCondition.year}年${this.searchCondition.quarter}季度考核部门未设置，是否设置`, '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
          this.showDeptSetting()
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        })
      }
    }

  }
}

</script>

<style scoped>
.platform-tag {
  border: #e97ca1 1px solid;
  line-height: 20px;
  margin-left: 10px;
  padding: 6px 10px;
  border-radius: 5px;

}
</style>
